// vue
import { ref, type Ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { Nullable, OrbitApiMeta, TutorPackageMatch } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useTutorPackageMatchStore =
  defineStore('tutor-package-match', () => {
    // fetch repositories
    const { $tutorPackageMatches } = useNuxtApp()

    // state
    const tutorPackageMatch =
      ref<Nullable<TutorPackageMatch>>(null) as Ref<TutorPackageMatch>
    const tutorPackageMatches =
      ref<TutorPackageMatch[]>([])

    // actions
    async function index (
      config?: NitroFetchOptions<string>
    ) {
      const { tutorPackageMatches: _tutorPackageMatches } =
        await $tutorPackageMatches
          .index<{ tutorPackageMatches: TutorPackageMatch[], meta: OrbitApiMeta}>(
            config
          )
      tutorPackageMatches.value = _tutorPackageMatches
      return tutorPackageMatches
    }

    async function show (
      id: number,
      config?: NitroFetchOptions<string>
    ) {
      const { tutorPackageMatch: _tutorPackageMatch } =
        await $tutorPackageMatches
          .show<{ tutorPackageMatch: TutorPackageMatch }>(id, config)
      tutorPackageMatch.value = _tutorPackageMatch
      return tutorPackageMatch
    }

    async function create (
      payload?: Record<string, unknown>,
      config?: NitroFetchOptions<string>
    ) {
      const { tutorPackageMatch: _tutorPackageMatch } =
        await $tutorPackageMatches
          .create<{ tutorPackageMatch: TutorPackageMatch }>(
            { tutorPackageMatch: payload },
            config
          )

      tutorPackageMatch.value = _tutorPackageMatch
      if (_tutorPackageMatch) {
        tutorPackageMatches.value =
          [...tutorPackageMatches.value, _tutorPackageMatch]
      }
      return tutorPackageMatch
    }

    async function update (
      id: number,
      payload?: Record<string, unknown>,
      config?: NitroFetchOptions<string>
    ) {
      const { tutorPackageMatch: _tutorPackageMatch } =
        await $tutorPackageMatches
          .update<{ tutorPackageMatch: TutorPackageMatch }>(
            id,
            { tutorPackageMatch: payload },
            config
          )

      if (_tutorPackageMatch) {
        tutorPackageMatch.value = {
          ...tutorPackageMatch.value,
          ..._tutorPackageMatch
        }

        if (tutorPackageMatches.value?.length) {
          tutorPackageMatches.value =
            tutorPackageMatches.value.filter(
              (tutorPackageMatch: TutorPackageMatch) => {
                return tutorPackageMatch.id !== id
              }
            )

          tutorPackageMatches.value =
            [...tutorPackageMatches.value, _tutorPackageMatch]
        }
      }

      return tutorPackageMatch
    }

    async function deleteTutorPackageMatch (
      id: number,
      payload?: Record<string, unknown>,
      config?: NitroFetchOptions<string>
    ) {
      await $tutorPackageMatches.delete(id, payload, config)

      if (tutorPackageMatches.value?.length) {
        tutorPackageMatches.value =
          tutorPackageMatches.value.filter(
            (tutorPackageMatch: TutorPackageMatch) => {
              return tutorPackageMatch.id !== id
            }
          )
      }
    }

    return {
      tutorPackageMatch,
      tutorPackageMatches,
      create,
      deleteTutorPackageMatch,
      index,
      show,
      update
    }
  })
